import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0,2,7,4,5,6];

export const dictionary = {
		"/(app)": [~8,[2]],
		"/(auth)/auth": [23,[7]],
		"/(auth)/auth/forgot-password": [24,[7]],
		"/(app)/auth/reset-password": [10,[3]],
		"/(auth)/auth/sign-up": [25,[7]],
		"/(app)/profile": [~11,[2]],
		"/(app)/project/new": [~19,[2,4]],
		"/(app)/project/[project]": [~12,[2,4,5]],
		"/(app)/project/[project]/edit": [~13,[2,4,5]],
		"/(app)/project/[project]/functionality/new": [~18,[2,4,5]],
		"/(app)/project/[project]/functionality/[functionality]": [14,[2,4,5,6]],
		"/(app)/project/[project]/functionality/[functionality]/edit": [~15,[2,4,5]],
		"/(app)/project/[project]/functionality/[functionality]/export": [~16,[2,4,5,6]],
		"/(app)/project/[project]/functionality/[functionality]/requirement/[requirement]": [~17,[2,4,5,6]],
		"/(app)/sorry-to-see-you-go": [20,[2]],
		"/(app)/thank-you": [21,[2]],
		"/(app)/welcome": [~22,[2]],
		"/(app)/[slug]/[code]": [~9,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';